.ce_rsce_faq{
    background-color: $secondary;
    padding: $elementSpacing $mainPadding;
    .inside{
        margin: auto;
        max-width: $maxContWidth;
		text-align: center;
		.headline-container{
			*{
				font-size: 2.5rem;
				font-weight: $fwBlack;
				line-height: 1;
				margin: 0;
			}
		}
		> .text-container{
            margin-block-start: 1rem;
			margin-inline: auto;
			max-width: 34.375rem;
            p{
                margin: 0;
            }
		}
        .faq-container{
            margin-block-start: 1.5rem;
			margin-inline: auto;
            max-width: 40.625rem;
            text-align: left;
            user-select: none;
            .question-container{
                position: relative;
                cursor: pointer;
                background-color: white;
                border-radius: 5px;
                transition: background-color ease .2s, box-shadow ease .2s, color ease .2s, opacity .6s cubic-bezier(.39,.575,.565,1), transform .6s cubic-bezier(.39,.575,.565,1);
                .toggle{
                    position: absolute;
                    top: .7rem;
                    left: 1.125rem;
                    height: 1.75rem;
                    width: 1.75rem;
                    i::before{
                        font-size: 1rem;
                        transition: transform ease .3s;
                    }
                }
                .question{
                    padding: .625rem 3.125rem;
                }
                .answer{
                    height: auto;
                    overflow: hidden;
                    transition: height .2s ease-out;
                    padding-inline: 3.125rem;
					.text-container{
						>*{
							margin-block: .5rem;
							&:first-child{
								margin-block-start: 0;
							}
							&:last-child{
								margin-block-end: .625rem;
							}
						}
					}
					ul{
						margin-block: .5rem;
						padding-inline-start: 1.5rem;
						li{
							font-size: .875rem;
							line-height: 1.28;
						}
					}
                    p{
                        font-size: .875rem;
                        line-height: 1.28;
                        margin-block-start: .5rem;
                    }
                }
                &:not(:first-of-type){
                    margin-block-start: .5rem;
                }
                &:not(.active):hover{
                    background-color: scale-color($color: $secondary, $lightness: 40%);
                }
                &.active{
                    background-color: $primary;
                    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
                    color: white;
                    .question{
                        font-weight: $fwSemibold;
                    }
                    .toggle{
                        i::before{
                            transform: rotateX(180deg);
                        }
                    }
                }
            }
        }
    }
}
