.hamburger {
	padding: 0;
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
    height: 26px;
    &.is-active{
        .hamburger-inner{
            transform: translate3d(0, 10px, 0) rotate(45deg);
            &,
            &::before,
            &::after{
                background-color: #000;
            }
            &::before{
                transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
                opacity: 0;
            }
            &::after{
                transform: translate3d(0, -20px, 0) rotate(-90deg);
            }
        }
    }
    .hamburger-inner{
        display: block;
        top: 2px;
        margin-top: -2px;
        &,
        &::before,
        &::after{
            width: 40px;
            height: 2px;
            background-color: #000;
            border-radius: 2px;
            position: absolute;
            transition: transform ease .15s, background-color linear .1s;
        }
        &::before,
        &::after{
            content: "";
            display: block;
        }
        &::before{
            top: 10px;
            transition: transform ease .15s, background-color linear .1s, opacity ease .15s;
        }
        &::after{
            top: 20px;
            bottom: -10px;
        }
    }
}

.hamburger-box {
    width: 40px;
    height: 24px;
    display: inline-block;
    position: relative;
}
