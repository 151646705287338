footer{
    .ce_rsce_footer{
        background: linear-gradient(292.7deg, #1378C0 18.83%, #3199E2 91.48%);
        padding: 2rem $mainPadding;
        .inside{
            color: white;
            margin-inline: auto;
            max-width: $maxContWidth;
            text-align: center;
			a{
				color: white;
			}
            .right{
                margin-block-start: $mainPadding;
                nav{
                    ul{
                        list-style: none;
                        margin: 0;
                        padding: 0;
                        li{
                            a{
                                display: inline-block;
                                margin: .25rem 0;
                                padding: .25rem 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

@mixin footer-tablet{
    footer{
        .ce_rsce_footer{
            .inside{
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                .right{
                    margin-block-start: 0;
                    nav{
                        ul{
                            display: flex;
                            flex-flow: row nowrap;
                            gap: 3.125rem;
                            li{
                                a{
                                    margin: 0;
                                    padding: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}