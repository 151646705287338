*{
	scroll-behavior: smooth;
}
body {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	min-height: 100vh;
}
*{
	outline-color: $primary;
	scroll-behavior: smooth;
}
#footer {
	margin-top: auto;
}
img {
	display: block;
	max-width: 100%;
	height: auto;
	width: auto;
}
