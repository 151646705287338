.form-input,
.widget-text input{
	background-color: rgba(0, 0, 0, .05);
	border: none;
	border-radius: 4px;
	color: black;
	outline: none;
	padding: .8125rem 1rem .75rem;
	transition: background-color ease .2s, box-shadow ease .2s;
	&:focus{
		background-color: rgba(0, 0, 0, .075);
		box-shadow: 0 0 0 4px scale-color($color: $primary, $alpha: -50%);
	}
	&::placeholder{
		color: rgba(0, 0, 0, .6);
	}
}

textarea{
	resize: none;
}

input[type=checkbox]{
	display: inline-block;
	margin: auto;
	position: relative;
	cursor: pointer;
	appearance: none;
	height: 2rem;
	width: 2rem;
	&::before{
		content: '\e800';
		font-family: 'xml-sitemaps-generator';
		font-style: normal;
		font-weight: normal;
		speak: never;
		display: inline-block;
		text-decoration: inherit;
		text-align: center;
		font-variant: normal;
		text-transform: none;
		line-height: 1em;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		height: .95rem;
		width: 2rem;
		color: white;
		z-index: 1;
		opacity: 0;
		transition: opacity ease .1s;
	}
	&::after{
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		height: 2rem;
		width: 2rem;
		background-color: rgba(0, 0, 0, .05);
		border-radius: 50%;
		transition: background-color ease .1s;
	}
	&:hover{
		&::after{
			background-color: rgba(0, 0, 0, .1);
		}
	}
	&:checked{
		&::before{
			opacity: 1;
		}
		&::after{
			background-color: $primary;
		}
	}
}