.ce_rsce_generator{
	margin-block: $elementSpacing;
	padding-inline: $mainPadding;
	.inside{
		margin-inline: auto;
		max-width: $maxContWidth5;
		.headline-container{
			*{
				font-size: 2.5rem;
				font-weight: $fwBlack;
				line-height: 1;
				margin: 0;
				text-align: center;
			}
		}
		.text-container{
			margin-block-start: 1rem;
			margin-inline: auto;
			max-width: $maxContWidth3;
			text-align: center;
		}
		.generator-container{
			background-color: white;
			border-radius: 10px;
			box-shadow: 0 0 40px rgba(0, 0, 0, .1);
			margin-block-start: 2rem;
			padding: 2rem;
			fieldset{
				border: none;
				padding: 0;
				margin-block: 2rem;
				legend{
					font-size: 1.5rem;
					font-weight: $fwSemibold;
					margin-block-end: 1rem;
				}
				&:first-of-type{
					margin-block-start: 0;
				}
				&:last-of-type{
					margin-block-end: 0;
				}
				ul{
					margin-block-end: 0;
				}
				.widget{
					display: flex;
					flex-flow: row nowrap;
					gap: .5rem;
					input{
						width: calc(100% - 13rem);
					}
					button{
						width: 12.5rem;
						white-space: nowrap;
					}
				}
			}
			.generator-output{
				background-color: rgba(0, 0, 0, .05);
				border-radius: 4px;
				font-family: $theme-font-family-monospace, $font-family-monospace;
				width: 100%;
				resize: none;
			}
			.generator-output-container{
				position: relative;
				&::after{
					content: 'In Zwischenablage kopiert';
					border-radius: .5rem;
					padding: .5rem .75rem;
					position: absolute;
					font-size: 1.25rem;
					top: 1rem;
					left: 50%;
					transform: translateX(-50%);
					background-color: white;
					box-shadow: 0 3px 8px rgba(0, 0, 0, .14);
					opacity: 0;
					transition: opacity ease .2s;
				}
				&.copied{
					&::after{
						opacity: 1;
					}
				}
			}
		}
	}
}