.ce_rsce_contact_form{
	margin-block: $elementSpacing;
	padding-inline: $mainPadding;
	.inside{
		margin-inline: auto;
		max-width: $maxContWidth;
		text-align: center;
		.headline-container{
			*{
				font-size: 2.5rem;
				font-weight: $fwBlack;
				line-height: 1;
				margin: 0;
			}
		}
		.headline-container,
		.text-container,
		.form-container{
			margin-inline: auto;
			max-width: $maxContWidth3;
		}
		.form-container{
			.formbody{
				fieldset{
					border: none;
					margin: 0;
					padding: 0;
				}
				> .widget{
					width: 100%;
				}
				label{
					display: none;
				}
				input, select, textarea{
					margin-block-end: 1rem;
					width: 100%;
				}
				.widget-explanation{
					margin-block-end: 1rem;
					p{
						font-size: .875rem;
						margin: 0;
						text-align: left;
					}
				}
			}
		}
	}
}

@mixin contact_form-tablet{
	.ce_rsce_contact_form{
		.inside{
			.form-container{
				form{
					fieldset{
						display: flex;
						flex-flow: row nowrap;
						justify-content: space-between;
						> *{
							width: calc(50% - .5rem);
						}
					}
				}
			}
		}
	}
}