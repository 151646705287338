@use "sass:math";

// Vertical Rhythm
//
// A constant vertical rythm is used throughout the theme. 
// This section explains the helper functions and variablews used to keep this consistent throughout.
//
// Styleguide 2.verticalRhythm

// Baseline and Midline
// 
// The vertical rhythm is based `$baseline` which is equal to the main line height (which is defined in settings),
// as well as a `$midline` which is equal to half the baseline. 
// Height of elements should be kept to multiples of `$baseline`. 
// `$midline` shold only be used in pairs (for example: `padding: $midline 0`)
//
// Styleguide 2.verticalRhythm.baseline
$baseline: $mainLineHeight * 1rem;
$midline: math.div($baseline, 2);

// Text Scaling
//
// `@include scaleText($size, $linesAbove, $linesBelow)`
// 
// Scales text in accordance with the vertical rythm.
//
// $size       - Die geforderte Größe des Texts relativ zur Root font-Größe.
// $linesAbove - Halbe Zeilen ($midline) über dem Absatz.
// $linesBelow - Halbe Zeilen ($midline) unter dem Absatz.
//
// Styleguide 2.verticalRhythm.textScaling
@mixin scaleText($size, $linesAbove: 1, $linesBelow: 1) {
	@if (unitless($size)) {
		$size: $size * 1rem;
	} @else if (unit($size) != rem) {
		@error "Please provide $size either unitless or in rem";
	}

	$i: 1;
	$buffer: 0.1rem;
	@while ($i * $midline <= $size + $buffer) {
		$i: $i + 1;
	}
	font-size: $size;
	line-height: $midline * $i;
	margin-top: $linesAbove * $midline;
	margin-bottom: $linesBelow * $midline;
}

// Element Scaling
//
// `@include scaleHeight($factor, $alignment)`
// 
// Skaliert ein Element vertikal wenn dieses eine Höhe haben soll welche Vom vertikalen Rhytmus abweicht.
// Kompensiert also für nicht ganzzahlige mehrfache von Midline und ermöglicht so abweichende
// Höhen ohne den Vertikalen Rhytmus zu stören.
//
// $factor     - Die angestrebte Höhe als (Nicht zwingend ganzzahliges) Mehrfaches von $midline.
// $allignment - (top, middle, bottom) Bestimmt die Vertikale Ausrichtung des skalierten Elements, default: middle
//
// Styleguide 2.verticalRhythm.elemScaling
@mixin scaleHeight($factor, $alignment: middle) {
	height: $midline * $factor;
	$rest: ceil($factor) - $factor;
	@if ($alignment == middle) {
		padding-top: $midline * ($rest / 2);
		padding-bottom: $midline * ($rest / 2);
	} @else if ($alignment == bottom) {
		padding-top: $midline * $rest;
	} @else if ($alignment == top) {
		padding-bottom: $midline * $rest;
	} @else {
		@error "`#{$alignment}` is not a valid arguement for $alignment. Valid arguements: `middle`, `top` and `bottom`.";
	}
}
