@font-face {
	font-display: swap;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 100;
	src: url('/files/themes/xml-sitemaps-generator/fonts/poppins/poppins-v21-latin-100.woff2') format('woff2');
}
@font-face {
	font-display: swap;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 200;
	src: url('/files/themes/xml-sitemaps-generator/fonts/poppins/poppins-v21-latin-200.woff2') format('woff2');
}
@font-face {
	font-display: swap;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 300;
	src: url('/files/themes/xml-sitemaps-generator/fonts/poppins/poppins-v21-latin-300.woff2') format('woff2');
}
@font-face {
	font-display: swap;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	src: url('/files/themes/xml-sitemaps-generator/fonts/poppins/poppins-v21-latin-regular.woff2') format('woff2');
}
@font-face {
	font-display: swap;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	src: url('/files/themes/xml-sitemaps-generator/fonts/poppins/poppins-v21-latin-500.woff2') format('woff2');
}
@font-face {
	font-display: swap;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	src: url('/files/themes/xml-sitemaps-generator/fonts/poppins/poppins-v21-latin-600.woff2') format('woff2');
}
@font-face {
	font-display: swap;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	src: url('/files/themes/xml-sitemaps-generator/fonts/poppins/poppins-v21-latin-700.woff2') format('woff2');
}
@font-face {
	font-display: swap;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 800;
	src: url('/files/themes/xml-sitemaps-generator/fonts/poppins/poppins-v21-latin-800.woff2') format('woff2');
}
@font-face {
	font-display: swap;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 900;
	src: url('/files/themes/xml-sitemaps-generator/fonts/poppins/poppins-v21-latin-900.woff2') format('woff2');
}

@font-face {
	font-display: swap;
	font-family: 'Lato';
	font-style: normal;
	font-weight: 100;
	src: url('/files/themes/xml-sitemaps-generator/fonts/lato/lato-v24-latin-100.woff2') format('woff2');
}
@font-face {
	font-display: swap;
	font-family: 'Lato';
	font-style: normal;
	font-weight: 300;
	src: url('/files/themes/xml-sitemaps-generator/fonts/lato/lato-v24-latin-300.woff2') format('woff2');
}
@font-face {
	font-display: swap;
	font-family: 'Lato';
	font-style: normal;
	font-weight: 400;
	src: url('/files/themes/xml-sitemaps-generator/fonts/lato/lato-v24-latin-regular.woff2') format('woff2');
}
@font-face {
	font-display: swap;
	font-family: 'Lato';
	font-style: normal;
	font-weight: 700;
	src: url('/files/themes/xml-sitemaps-generator/fonts/lato/lato-v24-latin-700.woff2') format('woff2');
}
@font-face {
	font-display: swap;
	font-family: 'Lato';
	font-style: normal;
	font-weight: 900;
	src: url('/files/themes/xml-sitemaps-generator/fonts/lato/lato-v24-latin-900.woff2') format('woff2');
}

@font-face {
	font-display: swap;
	font-family: 'Fira Code';
	font-style: normal;
	font-weight: 300;
	src: url('/files/themes/xml-sitemaps-generator/fonts/fira-code/fira-code-v22-latin-300.woff2') format('woff2');
}
@font-face {
	font-display: swap;
	font-family: 'Fira Code';
	font-style: normal;
	font-weight: 400;
	src: url('/files/themes/xml-sitemaps-generator/fonts/fira-code/fira-code-v22-latin-regular.woff2') format('woff2');
}
@font-face {
	font-display: swap;
	font-family: 'Fira Code';
	font-style: normal;
	font-weight: 500;
	src: url('/files/themes/xml-sitemaps-generator/fonts/fira-code/fira-code-v22-latin-500.woff2') format('woff2');
}
@font-face {
	font-display: swap;
	font-family: 'Fira Code';
	font-style: normal;
	font-weight: 600;
	src: url('/files/themes/xml-sitemaps-generator/fonts/fira-code/fira-code-v22-latin-600.woff2') format('woff2');
}
@font-face {
	font-display: swap;
	font-family: 'Fira Code';
	font-style: normal;
	font-weight: 700;
	src: url('/files/themes/xml-sitemaps-generator/fonts/fira-code/fira-code-v22-latin-700.woff2') format('woff2');
}

@font-face {
	font-family: 'xml-sitemaps-generator';
	src: url('/files/themes/xml-sitemaps-generator/fonts/iconfont/xml-sitemaps-generator.eot?7630766');
	src: url('/files/themes/xml-sitemaps-generator/fonts/iconfont/xml-sitemaps-generator.eot?7630766#iefix') format('embedded-opentype'),
		 url('/files/themes/xml-sitemaps-generator/fonts/iconfont/xml-sitemaps-generator.woff2?7630766') format('woff2'),
		 url('/files/themes/xml-sitemaps-generator/fonts/iconfont/xml-sitemaps-generator.woff?7630766') format('woff'),
		 url('/files/themes/xml-sitemaps-generator/fonts/iconfont/xml-sitemaps-generator.ttf?7630766') format('truetype'),
		 url('/files/themes/xml-sitemaps-generator/fonts/iconfont/xml-sitemaps-generator.svg?7630766#xml-sitemaps-generator') format('svg');
	font-weight: normal;
	font-style: normal;
}
[class^="icon-"]:before, [class*=" icon-"]:before {
	font-family: "xml-sitemaps-generator";
	font-style: normal;
	font-weight: normal;
	speak: never;
	display: inline-block;
	text-decoration: inherit;
	width: 1em;
	text-align: center;
	font-variant: normal;
	text-transform: none;
	line-height: 1em;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-down-open-big:before { content: '\e800'; } /* '' */