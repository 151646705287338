@use "sass:math";
/*
---
name: Clearfix
category: Mixins
---
Fügt einen clearfix hinzu.

**Nutzung:**
```scss
.element {
	@include clearfix;
}
```
**Resultat:**
```scss
.element {
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}
```
*/
@mixin clearfix {
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}

/*
---
name: Pseudo Element
category: Mixins
---
Fügt ein Pseude Element hinzu.

`@include pseudo($type, $display, $pos, $content){@content}`

Parameter | Beschreibung
----------|--------------
$type     | (`after`, `before`) Art des pseudo Elements. default: `after`
$display  | Display property des pseudo Elements. default: `block`
$pos      | Position property des pseudo Elements. default: `absolute`
$content  | `content` des pseudo Elements. default: `""`
@content  | Wird an den Körper des pseudo ELements angehängt.

**Nutzung:**
```scss
.element {
	@include pseudo(before, block, absolute, "") {
		left: 0;
		top: 0;
	}
}
```
**Resultat:**
```scss
.element {
	&:before {
		content: "";
		display: block;
		position: absolute
		left: 0;
		top: 0;
	}
}
```
*/
@mixin pseudo($type: after, $display: block, $pos: absolute, $content: "") {
	@if ($type == after) {
		&:after {
			content: $content;
			display: $display;
			position: $pos;
			@content;
		}
	} @else if ($type == before) {
		&:before {
			content: $content;
			display: $display;
			position: $pos;
			@content;
		}
	} @else {
		@error "#{$type} is not a valid value for $type, valid values: `before`, `after`";
	}
}

@mixin flex-grid($cols, $gap) {
	display: flex;
	flex-wrap: wrap;
	margin-top: $gap;
	margin-bottom: $gap;
	> * {
		$basis: (100 - ($gap * ($cols - 1))) / $cols;
		display: inline-block;
		width: $basis;
		&:not(:nth-child(#{$cols}n)):not(:last-child) {
			margin-right: $gap;
		}
		&:nth-child(n + #{$cols + 1}) {
			margin-top: $gap;
		}
	}
}

/*
---
name: Mediaquery
category: Mixins
---
Fügt einen Media query hinzu.

Parameter | Beschreibung
----------|--------------
$minWidth | Minimale Breite der Media query 
$maxWidth | Maximale Breite der Media query

**Nutzung:**
Das Verhalten dieses mixins lässt sich durch die Angabe von `$minWidth`, `$maxWidth` oder beiden zugleich steuren:
- Falls nur `$minWidth` angegeben ist wird eine Media query mit `(min-width: $minWidth)` ausgegeben.
- Falls nur `$maxWidth` angegeben ist wird eine Media query mit `(max-width: $maxWidth)` ausgegeben.
- Falls beide angegeben sind wird eine Media query mit `(min-width: $minWidth) and (max-width: $maxWidth)` ausgegeben.

**Beispiel:**
```scss
@include mq (300px, none) {
	body{color:green}
}
@include mq (none, 1200px) {
	body{color:red}
}
@include mq (300px, 1200px) {
	body{color:blue}
}
```
**Resultat:**
```css
@media only screen and (min-width: 300px) {
	body{color:green}
}
@media only screen and (max-width: 1200px) {
	body{color:red}
}
@media only screen and (min-width: 300px) and (max-width: 1200px) {
	body{color:blue}
}
```
*/
@mixin mq($minWidth: none, $maxWidth: none) {
	@if ($maxWidth != none) {
		@if (unit($maxWidth) == px) {
			$maxWidth: $maxWidth - 1px;
		} @else {
			$maxWidth: $maxWidth - 0.01rem;
		}
	}
	@if ($minWidth != none and $maxWidth != none) {
		@media only screen and (min-width: $minWidth) and (max-width: $maxWidth) {
			@content;
		}
	} @else if ($minWidth != none) {
		@media only screen and (min-width: $minWidth) {
			@content;
		}
	} @else if ($maxWidth != none) {
		@media only screen and (max-width: $maxWidth) {
			@content;
		}
	} @else {
		@error "Please specify a value for $minWidth, $maxWidth or both.";
	}
}

/** Positioniert ein Element Absolut und zentriert es im nächsten nicht-statischen
* Parent-Element.
* @Param {enum[both, vertical, horizontal]} $direction - Gibt die Richtungen an in
* denen das Element zentriert werden soll.*/
@mixin center($direction: both) {
	position: absolute;
	@if ($direction == both) {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	} @else if ($direction == horizontal) {
		left: 50%;
		transform: translateX(-50%);
	} @else if ($direction == vertical) {
		top: 50%;
		transform: translateY(-50%);
	} @else {
		@error "#{$direction} is not a valid value for $direction, valid values: `horizontal`, `vertical`, `both`";
	}
}

/** Positioniert ein Element Absolut und lässt es sein nächstes nicht-statisches
* Parent-Element bedecken. */
@mixin cover {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/** Gibt einen unitless Wert der Eingabe zurück.
* @param {css-unit} $number - Eingabewert
* @return {unitless} Der Eingabewert ohne Einheit. */
@function strip-unit($number) {
	@if type-of($number) == "number" and not unitless($number) {
		@return math.div($number, ($number * 0 + 1));
	}
	@return $number;
}
