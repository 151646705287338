.ce_rsce_text_custom{
	margin-block: $elementSpacing;
	padding-inline: $mainPadding;
	.inside{
		margin-inline: auto;
		max-width: $maxContWidth;
		.headline-container{
			*{
				font-size: 2.5rem;
				font-weight: $fwExtrabold;
				line-height: 1;
				margin: 0;
			}
		}
		.text-container{
			h2, h3, h4, h5, h6{
				font-weight: $fwBold;
			}
		}
	}
	&.align-left{
		.inside{
			.headline-container,
			.text-container,
			.buttons-container{
				max-width: $maxContWidth4;
			}
		}
	}
	&.align-center{
		.inside{
			text-align: center;
			.headline-container,
			.text-container,
			.buttons-container{
				margin-inline: auto;
				max-width: $maxContWidth3;
			}
		}
	}
}