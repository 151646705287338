body {
	margin: 0; // 1
	font-family: $theme-font-family, $font-family-base;
	font-weight: $fwLight;
	line-height: $mainLineHeight;
	color: $fontColor;
	text-align: left;
	background-color: transparent; // 2
	-webkit-text-size-adjust: 100%; // 3
	-webkit-tap-highlight-color: rgba($black, 0); // 4
	hyphens: none;
}

a{
    color: $link-color;
    text-decoration: $link-decoration;
    &:hover{
        color: $link-hover-color;
        text-decoration: $link-hover-decoration;
    }
}

h1, h2, h3, h4, h5, h6 {
	color: $headings-color;
	font-weight: $fwExtrabold;
	font-family: $heading-font-family, $font-family-base;
	hyphens: none;
	line-height: 1;
}

h1 {
	@include scaleText($size: msInt(6), $linesAbove: 6, $linesBelow: 4);
	font-size: 2.5rem;
}
h2 {
	@include scaleText($size: msInt(5), $linesAbove: 4, $linesBelow: 2);
	font-size: 2rem;
}
h3 {
	@include scaleText($size: msInt(4), $linesAbove: 4, $linesBelow: 2);
	font-size: 1.75rem;
}
h4 {
	@include scaleText($size: msInt(3), $linesAbove: 2, $linesBelow: 2);
	font-size: 1.5rem;
}
h5 {
	@include scaleText($size: msInt(2), $linesAbove: 2, $linesBelow: 2);
	font-size: 1.25rem;
}
h6 {
	@include scaleText($size: msInt(1), $linesAbove: 2, $linesBelow: 2);
	font-size: 1rem;
}
p,
table,
ul,
ol,
blockquote,
figure {
	@include scaleText($size: 1, $linesAbove: 2, $linesBelow: 2);
}
small {
	@include scaleText($size: msInt(-1), $linesAbove: 1, $linesBelow: 1);
}
/*! Fluid Type */
@include fluid-type($minFontSize, $maxFontSize, $minFontWidth, $maxFontWidth);