.button{
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
    font-family: Poppins, $font-family-base;
    font-weight: $fwSemibold;
    user-select: none;
    &.button-primary{
        background: linear-gradient(93.58deg, #348ECE 0%, #1378C0 100%);
        background-size: 100% 100%;
        box-shadow: 0px 10px 20px rgba(19, 120, 192, 0.1);
        color: white;
        font-size: 1rem;
        line-height: 1;
        padding: .9375rem 2.125rem;
        transition: background-position ease .25s, background-size ease .25s, box-shadow ease .25s;
        &:hover{
            background-position: 100% 100%;
            background-size: 200% 200%;
            box-shadow: 0px 10px 20px rgba(19, 120, 192, 0.2);
        }
    }
	&.button-red{
		background-color: $red !important;
        box-shadow: 0px 10px 20px rgba(19, 120, 192, 0.1);
		color: white;
	}
    &.button-teaser{
        background-color: white;
        box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
        color: $primary;
        font-size: 1.25rem;
        line-height: 1.2;
        padding: 1.125rem 2.5rem;
    }
    &:hover{
        text-decoration: none;
    }
	&.loading{
		color: transparent !important;
		pointer-events: none;
		position: relative;
		&::after{
			animation: spin 1s linear infinite;
			border: .25rem solid rgba(255, 255, 255, .2);
			border-right-color: white;
			border-radius: 50%;
			content: '';
			height: 1.5rem;
			margin-inline: 0 0 0 .5rem;
			width: 1.5rem;
			position: absolute;
			top: 50%;
			left: 50%;
		}
	}
}
@keyframes spin{
	0%{
		transform: translate(-50%, -50%) rotate(0deg);
	}
	100%{
		transform: translate(-50%, -50%) rotate(360deg);
	}
}