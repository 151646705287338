/*! --Breakpoints-- */
@include mq($mobile_s) {

}

@include mq($mobile) {
	
}

@include mq($mobile_l) {
	
}

@include mq($tablet_s) {
	
}

@include mq($tablet) {
	@include header-tablet;
	@include footer-tablet;

	@include contact_form-tablet;
	@include teaser-tablet;
}

@include mq($tablet_l) {
	
}

@include mq($desktop_s) {
	
}

@include mq($desktop) {
	
}

@include mq($desktop_l) {
	
}
