@use "sass:math";

// Fluid-Type
//
// `@include fluid-type($minFontSize, $maxFontSize, $minFontWidth, $maxFontWidth)`
//
// Wir benutzen fluid-type, d.h. Flüssige Fontskalierung von einer minimalen zu einer maximalen Textgröße 
// zwischen einer minimalen und einer maximalen Bildschirmgröße. 
//
// [Ausführliche Erklärung von css-tricks](https://css-tricks.com/snippets/css/fluid-typography/)
//
// Die Einstellungen dazu finden sich in der `main.scss`.
//
// $minFontSize - minimale Textgröße, default: 14px
// $maxFontSize - maximale Textgröße, default: 18px
// $minWidth - minimale Bildschirmbreite, default: 320px
// $maxWidth - maximale Bildschirmbreite, default: 1920px
//
// Styleguide 2.1
@mixin fluid-type(
	$minFontSize: 14px,
	$maxFontSize: 18px,
	$minWidth: 320px,
	$maxWidth: 1920px
) {
	/*! Min */
	html {
		font-size: $minFontSize;
	}

	/*! Fallback */
	$unitlessMinFontSize: strip-unit($minFontSize);
	$unitlessMaxFontSize: strip-unit($maxFontSize);
	$steps: $unitlessMaxFontSize - $unitlessMinFontSize;
	$widthInterval: math.div(($maxWidth - $minWidth), $steps);
	$i: 1;
	@for $size from $minFontSize + 1px to $maxFontSize {
		@include mq($minWidth + $widthInterval * $i) {
			html {
				font-size: $size;
			}
		}
		$i: $i + 1;
	}

	/*! Fluid */
	@media all and (min-width: $minWidth) {
		html {
			font-size: calc(
				#{$minFontSize} + #{strip-unit($maxFontSize - $minFontSize)} *
					(100vw - #{$minWidth}) / #{strip-unit($maxWidth - $minWidth)}
			);
		}
	}

	/*! Max */
	@include mq($maxWidth) {
		html {
			font-size: $maxFontSize;
		}
	}
}
