.ce_rsce_teaser{
    background: linear-gradient(292.7deg, #1378C0 18.83%, #3199E2 91.48%);
    display: flex;
    flex-flow: column;
    justify-content: center;
    min-height: 70vh;
    padding-inline: $mainPadding;
    .inside{
        display: flex;
        flex-flow: column-reverse;
        margin-inline: auto;
        max-width: $maxContWidth2;
        width: 100%;
        .left{
            display: flex;
            flex-flow: column;
            justify-content: center;
            max-width: 26.75rem;
            .headline-container{
                margin-block-end: 1.5rem;
                *{
                    color: rgba(255, 255, 255, 0.6);
                    font-size: 2rem;
                    font-weight: $fwBold;
                    line-height: 1;
                    margin: 0;
                    strong{
                        color: white;
                        font-weight: $fwBold;
                    }
                }
            }
            .text-container{
                margin-block-end: 2.125rem;
                p{
                    color: white;
                    font-size: 1.25rem;
                    line-height: 1.2;
                    margin: 0;
                }
            }
        }
        .right{
            margin-block-end: 2.5rem;
            max-width: 22.5rem;
            img{
				max-width: 50vw;
				margin-inline: auto;
                opacity: .6;
				width: 100%;
                filter: drop-shadow(0px 0px 100px rgba(0, 0, 0, 0.25));
            }
        }
    }
}

@mixin teaser-tablet{
    .ce_rsce_teaser{
        .inside{
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
			.left{
				.headline-container{
					*{
						font-size: 4rem;
					}
				}
			}
			.right{
				margin-block-end: 0;
			}
        }
    }
}