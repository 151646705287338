header{
	background-color: white;
	padding: 1rem $mainPadding;
	z-index: 100;
	.ce_rsce_header{
		.inside{
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;
			margin-inline: auto;
			max-width: $maxContWidth;
			.left{
				padding-inline-end: 1.5rem;
				img{
					max-height: 5rem;
					transition: max-height ease .15s;
					z-index: 10;
				}
			}
			.right{
				nav{
					.hamburger{
						z-index: 10;
					}
					>ul{
						position: fixed;
						top: 0;
						left: 0;
						height: 100%;
						width: 100%;
						background-color: white;
						opacity: 0;
						pointer-events: none;
						transition: opacity ease .2s;
						&.active{
							opacity: 1;
							pointer-events: initial;
						}
						li{
							a{
								padding: calc(#{$mainPadding} / 2) $mainPadding;
							}
							strong, a.active{
								font-weight: $fwBold;
							}
						}
					}
				}
			}
		}
	}
}

body.scrolled{
	header{
		.ce_rsce_header{
			.inside{
				.left{
					img{
						max-height: 4rem;
					}
				}
			}
		}
	}
}

body:has(.mod_article > .ce_rsce_teaser:first-of-type){
	header{
		background-color: transparent;
		position: fixed;
		width: 100%;
		.ce_rsce_header{
			.inside{
				.left{
					img{
						filter: invert(1) brightness(1000);
					}
				}
			}
		}
	}
	&.scrolled{
		header{
			background-color: white;
			box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
			.ce_rsce_header{
				.inside{
					.left{
						img{
							filter: invert(0) brightness(100%);
						}
					}
				}
			}
		}
	}
}


@mixin header-tablet{
	header{
		.ce_rsce_header{
			.inside{
				.right{
					nav{
						height: 100%;
						.hamburger{
							display: none;
						}
						ul{
							position: static;
							height: initial;
							width: initial;
							background-color: transparent;
							opacity: 1;
							pointer-events: initial;
							list-style: none;
							display: flex;
							flex-flow: row nowrap;
							gap: 3.125rem;
							margin: 0;
							padding: 0;
							align-items: center;
							height: 100%;
							li{
								a{
									margin: 0;
									padding: 0;
									color: black;
									&:hover{
										color: $primary;
										text-decoration: none;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	body:has(.mod_article > .ce_rsce_teaser:first-of-type){
		header{
			.ce_rsce_header{
				.inside{
					.right{
						nav{
							ul{
								li{
									a{
										color: white;
										&:hover{
											color: #ddd;
										}
									}
								}
							}
						}
					}
				}
			}
		}
		&.scrolled{
			header{
				.ce_rsce_header{
					.inside{
						.right{
							nav{
								ul{
									li{
										a{
											color: $primary;
											&:hover{
												color: scale-color($color: $primary, $lightness: -40%);
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}